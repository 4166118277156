<template>
  <div class="h-100 w-100 business-partner-request-wrapper d-flex">
    <!-- Left Section: Logo and App Title -->
    <div class="p-3 mb-xl-0 left-section d-flex flex-column justify-content-center align-items-center text-center">
      <div v-if="appInfo && appInfo.image_file_name" class="mb-3">
        <img :src="`/storage/LOGOS/${appInfo.image_file_name}`" alt="App Logo" class="Loginlogo" />
      </div>
      <h1 class="fw-bold loginTitle" style="color: #175576">My City Market</h1>
      <span class="fs-6" style="color: #175576">παροχή του</span><br>
      <img class="secondLogo" src="../assets/images/home/logo-epimelitirio-dark.png" alt="" >
    </div>

    <!-- Right Section: Request Form -->
    <div class="right-section d-flex flex-column justify-content-center align-items-center mb-5 mb-xl-0">
      <div class="form-container">
        <h3 class="fs-1 fw-bold loginTitle">ΕΓΓΡΑΦΗ</h3>
        <small class="mb-3 d-block">Παρακαλώ συμπληρώστε τα στοιχεία της αίτησής σας.</small>


        <form class="row g-1 p-3 p-md-2 loginContentsArea" @submit.prevent="sendRequest">
          
          <div class="userLoginContentsArea">
            <!-- Row for ΑΦΜ and Διεύθυνση Email -->
            <div class="row gap-5 mb-2">
              <div class="col mb-2 px-0">
                <label class="form-label">ΑΦΜ *</label>
                <input maxlength = "9" type="text" class="form-control form-control-md" placeholder="#########" name="afm" v-model="afm">
              </div>
              <div class="col mb-2 px-0">
                <label class="form-label">Διεύθυνση Email *</label>
                <input type="email" class="form-control form-control-md" placeholder="name@example.com" name="email" v-model="email">
              </div>
            </div>
            
            <!-- Row for Ονοματεπώνυμο αιτούντος and Εγκεκριμένη επωνυμία -->
            <div class="row mb-2">
              <div class="col-12 mb-2 px-0">
                <label class="form-label">Ονοματεπώνυμο αιτούντος *</label>
                <input type="text" class="form-control form-control-md" placeholder="π.χ. Γιώργος..." name="fullname" v-model="fullname">
              </div>
             
            </div>

            <div class="row mb-2">
            <div class="col-12 mb-2 px-0">
                <label class="form-label">Εγκεκριμένη επωνυμία *</label><br />
<!--                 <small>(Μετά την έγκριση της αίτησής σας θα είναι και το όνομα της σελίδας σας στο My City Market)</small>
 -->                <input type="text" class="form-control form-control-md" placeholder="π.χ. Company" name="brandname" v-model="brandname">
              </div>
            </div>

            
            <!-- Row for Νομική επωνυμία and checkbox -->
            <div class="row mb-2 align-items-end">
              <div class="col-md-9 mb-2 px-0">
                <label class="form-label">Νομική επωνυμία *</label>
                <input type="text" class="form-control form-control-md" placeholder="π.χ. Legal Name Company" name="trademark" v-model="trademark" :disabled="trademarkSame">
              </div>
              <div class="col-md-3 d-flex align-items-center">
                <input type="checkbox" @click="toggleTrademark" class="me-2">
                <small>Ίδιο με την Εγκεκριμένη επωνυμία</small>
              </div>
            </div>
            
            <!-- Submit Button -->
            <div class="col-12 d-flex align-items-center justify-content-between mt-4">
              <div>
                <button type="submit" class="btn px-4 py-2 fw-bold text-white bg-primary btn-submit" :disabled="isSubmitting || requestSent">
                Αποστολή αίτησης
                </button>
              </div>
              <div>
                <span class="btn fw-bold px-4 py-2 text-white" style="background: lightgrey;"><a class="text-white" href="/merchant">Πίσω στη Σύνδεση</a></span>
              </div>
              

            </div>
          </div>
          <div v-if="errorMessage" class="error-div text-center mt-2">
            <p>{{ errorMessage }}</p>
          </div>
        </form>

        <form class="row g-1 p-3 p-md-2 verificationContentsArea" style="display: none;" @submit.prevent="validateCode">
          
          <div>
            <!-- Row for ΑΦΜ and Διεύθυνση Email -->
            <div class="row gap-5 mb-2">
              <div class="col mb-2 px-0">
                <label class="form-label">Εισάγετε τον 6ψήφιο κωδικό που λάβατε στο email σας</label>
                <input type="text" class="form-control form-control-md" placeholder="#########" name="code" v-model="code">
              </div>
            </div>
            
            <!-- Submit Button -->
            <div class="col-12 d-flex align-items-center justify-content-between mt-4">
              <div>
                <button type="submit" class="btn px-4 py-2 fw-bold text-white bg-primary btn-submit" :disabled="isSubmitting || requestSent">
                Επιβεβαίωση κωδικού
                </button>
              </div>
              <div>
                <button @click="goToLogin" class="btn fw-bold px-4 py-2 text-white" style="background: lightgrey;">Σύνδεση</button>
              </div>
              

            </div>
          </div>
          <div v-if="errorMessage" class="error-div text-center mt-2">
            <p>{{ errorMessage }}</p>
          </div>
        </form>

      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import { sendRequestService } from '../services/business-partners.js';
import { useRouter } from 'vue-router';
import { getAppInfo } from '@/services/appInfo.js';
import { useToast } from 'vue-toastification';
import { validateVerificationCode, sendVerificationCode } from '@/services/auth.js';
export default {
  name: 'BusinessPartnerRequest',
  setup() {
    const router = useRouter();
    const afm = ref('');
    const email = ref('');
    const fullname = ref('');
    const brandname = ref('');
    const trademark = ref('');
    const trademarkSame = ref(false);
    const errorMessage = ref(null);
    const requestSent = ref(false);
    const isSubmitting = ref(false);
    const appInfo = ref(null);
    const token = ref('');
    const code = ref('');

    const handleKeyPressed = (event) => {
      if (event.key == 'Enter') {
        document.querySelector('.btn-submit').click();
      }
    };

    onMounted(async () => {
      try {
        const info = await getAppInfo();
        appInfo.value = info.data.success ? info.data.data : false;
      } catch (e) {
        errorMessage.value = 'Failed to load app info';
      }
      document.addEventListener('keyup', handleKeyPressed);
    });

    onUnmounted(() => {
      document.removeEventListener('keyup', handleKeyPressed);
    });

    const toggleTrademark = () => {
      trademarkSame.value = !trademarkSame.value;
      if (trademarkSame.value) {
        trademark.value = brandname.value;
      } else {
        trademark.value = '';
      }
    };

    const validateCode = async () => {
      errorMessage.value = '';
      const r = await validateVerificationCode(token.value, code.value);
      if (r.data.success) {
        $(".loginContentsArea").show();
        $(".verificationContentsArea").hide();
        code.value = '';
        sendRequest();        
      } else {
        errorMessage.value = r.data.message;
      }
    };

    const sendRequest = async () => {
      if (isSubmitting.value) return;
      if (requestSent.value) return;
      isSubmitting.value = true;
      errorMessage.value = null;
      try {
        const payload = {
          afm: afm.value,
          email: email.value,
          fullname: fullname.value,
          brandname: brandname.value,
          trademark: trademark.value,
          trademarkSame: trademarkSame.value ? 1 : 0,
        };
        if (token.value != '') {
          payload.token = token.value;
        }
        const response = await sendRequestService(payload);
        if (response.data?.success) {
          if (response.data.data['2fa'] == true) {
            const r = await sendVerificationCode(email.value);
            if (r.data.success) {
              token.value = r.data.data;
              $(".loginContentsArea").hide();
              $(".verificationContentsArea").show();
              return;
            }
          }
          useToast().success('Η αίτησή σας καταχωρήθηκε επιτυχώς');
          requestSent.value = true;
          router.push('/login');
        } else {
          errorMessage.value = 'Υπήρξε πρόβλημα κατά την αποστολή της αίτησης σας.';
        }
      } catch (e) {
        errorMessage.value = 'Υπήρξε πρόβλημα κατά την αποστολή της αίτησης σας.';
      } finally {
        isSubmitting.value = false;
      }
    };

    const goToLogin = () => {
      router.push('/login');
    };

    return {
      validateCode,
      token,
      code,
      afm,
      email,
      fullname,
      brandname,
      trademark,
      trademarkSame,
      errorMessage,
      requestSent,
      isSubmitting,
      appInfo,
      toggleTrademark,
      sendRequest,
      goToLogin
    };
  }
};
</script>

<style scoped>
/* Main Wrapper to hold the left and right sections */
.business-partner-request-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100vh !important;
}
.Loginlogo{
  height: 200px;
}
.loginTitle{
    text-shadow: 2px 0px;
}
/* Left Section: App info, occupies half the screen */
.left-section {
  background-color: rgb(240, 240, 240);
  width: 50%; /* Takes up half the screen */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Right Section: Request form, occupies the other half */
.right-section {
  width: 50%; /* Takes up half the screen */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

/* The form container */
.form-container {
  width: 100%;
  max-width: 600px;
}

/* Input fields styling */
.form-control-lg {
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Error message styling */
.error-div {
  color: red;
  text-align: center;
}

.form-control {
  width: 100%;
  /* box-shadow: 0px 0px 7px #dedede; */
}

.secondLogo{
  height: 90px;
}

/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
  .business-partner-request-wrapper {
    flex-direction: column;
    justify-content: start;
  }
  .Loginlogo{
    height: 120px;
  }
  .left-section,
  .right-section {
    width: 100%; /* Stack the sections vertically on smaller screens */
  }
}
</style>
