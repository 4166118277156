import apiClient from './api';

async function fetchAll(data) {
  try {
    const response = await apiClient.get('/getallbusinesspartners', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function getAllBusinessPartners(data) {
  try {
    const response = await apiClient.get('/getallbusinesspartners-lat-lon', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function getBusinessPartnersPerCategoryId(data) {
  try {
    const response = await apiClient.get('/getbusinesspartnerspercategoryid', {params: data || {}}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function getCurrentBusinessPartnerData(data) {
  try {
    const response = await apiClient.get('/getcurrentbusinesspartnerdata', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function updateCurrentBusinessPartnerData(data) {
  try {
    const config = {
      headers: {
        // Automatically set the content-type based on the type of data
        'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
      },
    };

    const response = await apiClient.post('/updatecurrentbusinesspartnerdata', data, config);
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function sendRequestService(data) {
  try {
    const response = await apiClient.post('/create-business-partner-request', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function get(data) {
  try {
    const response = await apiClient.get('/getbusinesspartnerdata', {params: data || {}}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function searchBusinessPartner(data) {
  try {
    const response = await apiClient.get('/search-business-partner', {params: data || {}}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function getBusinessPartnerInfo(data) {
  try {
    const response = await apiClient.get('/get-businesspartner-info/' + (data.id || 0), {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

export {fetchAll, get, getAllBusinessPartners, sendRequestService, updateCurrentBusinessPartnerData, searchBusinessPartner, getBusinessPartnersPerCategoryId, getCurrentBusinessPartnerData, getBusinessPartnerInfo}