<template>
  <div>
    <input 
      type="text" 
      placeholder="Εισάγετε τον Τ.Κ. σας"
      v-model="searchQuery" 
      @keyup.enter="focusOnZip" 
      style="margin-bottom: 10px; width: 100%; padding: 8px;border-radius: 5px;border: 1px inset #8FBDE9;"
    />
    <div id="map" style="height: 450px; width: 100%;"></div>
  </div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";

const customMarkerIcon = L.icon({
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  shadowSize: [41, 41],
});

export default {
  name: "LeafletMap",
  props: {
    locations: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every(
          (location) =>
            typeof location === "object" &&
            "lat" in location &&
            "lon" in location &&
            "title" in location &&
            typeof location.lat === "number" &&
            typeof location.lon === "number" &&
            typeof location.title === "string"
        ),
    },
  },
  data() {
    return {
      map: null,
      markers: [],
      searchQuery: "",
    };
  },
  watch: {
    locations: {
      handler(newLocations) {
        this.updateMarkers(newLocations);
      },
      deep: true,
    },
  },
  mounted() {
    this.initMap();
    this.updateMarkers(this.locations);
  },
  methods: {
    async initMap() {
      this.map = L.map("map").setView([37.9838, 23.7275], 7); // Default view over Greece

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
      }).addTo(this.map);
    },
    updateMarkers(locations) {
      // Clear existing markers
      this.markers.forEach((marker) => this.map.removeLayer(marker));
      this.markers = [];

      // Add new markers
      locations.forEach(({ lat, lon, title }) => {
        const marker = L.marker([lat, lon], { icon: customMarkerIcon }).addTo(this.map);
        marker.bindPopup(title || '');
        this.markers.push(marker);
      });

      // Adjust map bounds
      const validMarkers = this.markers.map((marker) => marker.getLatLng());
      if (validMarkers.length) {
        const bounds = L.latLngBounds(validMarkers);
        this.map.fitBounds(bounds);
      }
    },
    invalidateSize() {
      // Invalidate map size to ensure proper rendering
      if (this.map) {
        this.map.invalidateSize();
      }
    },
    async focusOnZip() {
      if (!this.searchQuery) return;

      if (!this.map) {
        alert("Map is not initialized yet. Please wait.");
        return;
      }

      try {
        const { data } = await axios.get("https://nominatim.openstreetmap.org/search", {
          params: {
            postalcode: this.searchQuery,
            country: "Greece",
            format: "json",
            limit: 1,
          },
        });

        if (data.length > 0) {
          const { lat, lon } = data[0];
          this.map.setView([lat, lon], 14); // Focus on the ZIP code area
        } else {
          alert("ZIP code not found. Please try a different query.");
        }
      } catch (error) {
        console.error("Error focusing on ZIP code:", error);
        alert("An error occurred while searching for the ZIP code.");
      }
    },
  },
};
</script>

<style scoped>
#map {
  height: 100%;
  width: 100%;
}
</style>
