<template>
    <div id="mytask-layout" class="theme-indigo">

        <!-- main body area -->
        <div class="main" style="display: flex; align-items: center;">

            <!-- Body: Body -->
            <div class="body d-flex p-0 p-xl-5" style="max-height:95%">
                <div class="container-xxl">
                    <div v-if="isLoading"
                        class="loadingDiv d-flex flex-column align-items-center justify-content-center">
                        <div class="text-center">
                            <div style="gap:30px" class="d-flex align-items-baseline">
                                <div>
                                    <h3 class="text-light">H εγγραφή είναι σε εξέλιξη</h3>
                                </div>
                                <div class="dot-flashing"></div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row g-0 mb-5">
                            <div class="col-lg-12 d-flex justify-content-center align-items-center border-0 rounded-lg">
                                <div class="w-100 card border-0 text-light login-div"
                                    style="max-width: 50rem; background: #fff !important; color: #999999 !important">

                                    <div class="">
                                        <h2 class="text-center"><span
                                                style="font-size: 25px; line-height: 25.2px; font-family: 'Open Sans', sans-serif;"><strong><span
                                                        style="font-size: 30px; line-height: 33.6px;">My City Market</span></strong></span>
                                        </h2>
                                    </div>
                                    <!-- Form -->
                                    <form class="row g-1 p-3 p-md-4" @submit.prevent="handleSubmit">
                                        <div class="col-12 text-center mb-1 mb-lg-5">
                                            <h3><b>Εγγραφή νέου πελάτη</b></h3>
                                            <span>Εισάγετε τα στοιχεία του λογαριασμού σας.</span>
                                        </div>
                                <div class="col-md-6">
                                    <div class="mb-2">
                                        <label class="form-label fw-bold">Όνομα Δήμου</label>
                                        <input type="text" class="form-control form-control-md municipality_title" placeholder="Δήμος Χολαργού" v-model="form.municipality_title">
                                    </div>
                                </div>
                                
                                <div class="col-md-6">
                                    <div class="mb-2">
                                        <label class="form-label fw-bold">Διεύθυνση email</label>
                                        <input type="email" class="form-control form-control-md email" placeholder="name@example.com"  v-model="form.email">
                                    </div>
                                </div>
                             
                                <div class="col-md-6">
                                    <label for="image_file_name" class="form-label fw-bold">Logo</label>
                                    <input type="file" class="form-control" id="image_file_name" name="image_file_name" @change="handleFileChange" />
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-2">
                                        <label class="form-label fw-bold">Domain</label>
                                        <input type="text" class="form-control form-control-md domain" placeholder="dimos-xolargou.com" v-model="form.domain">
                                    </div>
                                </div>
                                        <div class="col-md-6">
                                            <div class="mb-2">
                                                <div class="form-label">
                                                    <span
                                                        class="fw-bold d-flex justify-content-between align-items-center">
                                                        Κωδικός πρόσβασης
                                                    </span>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <input id="password" type="password"
                                                        class="form-control form-control-md" v-model="form.password"
                                                        placeholder="******">
                                                    <span class="showPassword2Icon"
                                                        onclick="showPasswordField('showPassword')"><i
                                                            class="icofont-eye-alt"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-2">
                                                <div class="form-label">
                                                    <span
                                                        class="fw-bold d-flex justify-content-between align-items-center">
                                                        Επιβεβαίωση κωδικού πρόσβασης
                                                    </span>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <input id="password_confirmation" type="password"
                                                        class="form-control form-control-md"
                                                        v-model="form.password_confirmation" placeholder="******">
                                                    <span class="showPassword2Icon" style="right:7%"
                                                        onclick="showPasswordField('showPassword2')"><i
                                                            class="icofont-eye-alt"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-12 text-center mt-4">
                                            <button type="submit"
                                                class="btn btn-lg btn-block btn-light lift btn-register btn-primary"><b>Εγγραφή</b></button>
                                        </div>
                                        <div class="col-12 text-center mt-3">
                                            <a href="/"
                                                style="text-decoration: underline;color: gray; font-size: 12px;">Πίσω
                                                στην σύνδεση</a>
                                        </div>
                                        
                                    </form>
                                    <!-- End Form -->
                                </div>
                            </div>
                        </div> <!-- End Row -->
                    </div>
                    <div id="extra-content">
                    </div>
                </div>
            </div>
            <!-- <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
            <div v-if="successMessage" class="success-message">{{ successMessage }}</div> -->
        </div>
    </div>

</template>

<script>
import '../assets/js/bundles/libscripts.bundle.js';
import { ref, onMounted, onUnmounted } from 'vue';
import { register as Register } from '../services/auth.js';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import router from '@/router';



export default {
    name: 'Register',
    setup() {
        const form = ref({
            name: '',
            surname: '',
            email: '',
            gender: 'Μ',
            password: '',
            password_confirmation: '',
            company_afm: '',
            company_title: '',
            company_job_description: '',
            company_address: ''
        });

        const file = ref(null);

        const isLoading = ref(false);
        const errorMessage = ref('');
        const successMessage = ref('');

        const toast = useToast();

        const handleFileChange = (event) => {
            file.value = event.target.files[0]; // Save the file to the ref
        };

        const handleSubmit = async () => {
            isLoading.value = true;
            try {
                const formData = new FormData();
                formData.append('municipality_title', form.value.municipality_title);
                formData.append('domain', form.value.domain);
                formData.append('email', form.value.email);
                formData.append('password', form.value.password);
                formData.append('password_confirmation', form.value.password_confirmation);

                if (file.value) {
                    formData.append('image_file_name', file.value); // Append the file to FormData
                }

                const response = await Register(formData);
                if (response.data?.success) {
                    successMessage.value = response.data.message;
                    toast.success(response.data.message);
                    setTimeout(() => {
                        router.push('/login');
                    }, 2000);
                } else {
                    errorMessage.value = response.data.message;
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Registration error:', error);
                errorMessage.value = 'An error occurred while registering. Please try again later.';
                toast.error('An error occurred while registering. Please try again later.');
            } finally {
                isLoading.value = false;
            }
        };


        const handleKeyPressed = (event) => {
            if (event.key == 'Enter') {
                document.querySelector('.btn-register').click();
            }
        };

        onMounted(() => {
            document.addEventListener('keyup', handleKeyPressed);
        });

        onUnmounted(() => {
            document.removeEventListener('keyup', handleKeyPressed);
        });

       

        return {
            form,
            isLoading,
            errorMessage,
            successMessage,
            handleFileChange,
            handleSubmit
        };
    }
};
</script>

<style>
/* Styles specific to login view */
</style>
