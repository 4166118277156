<template>
  <div class="container-fluid px-4 px-xl-5 mt-3 mt-xl-0 mb-5">
    <button 
        class="fixed-button" 
        data-bs-toggle="modal" 
        data-bs-target="#mapModal"
      >
        
        <span class="button-text"><i class="icofont-eye"></i>Προβολή χάρτη</span>
      </button>
    <!-- Breadcrumbs -->
    <nav class="mt-xl-5 mt-1" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item ">
          <router-link class="color-600" to="/">Αρχική</router-link>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page"><b>{{ categoryTitle }}</b></li>
      </ol>
    </nav>

    <div class="row d-flex align-items-center text-end mb-4">
        <div class="d-flex gap-3 col-12 col-xl-3 justify-content-center">
                <button 
                @click="randomizeData" 
                :class="['btn-sorting', activeSort === 'random' ? 'active' : '']">
                <i class="icofont-random"></i>&nbsp;
                <span>Τυχαία προβολή</span>
                </button>
                <button 
                    @click="sortAlphabetically" 
                    :class="['btn-sorting', activeSort === 'alphabetical' ? 'active' : '']">
                    <i class="icofont-text-height"></i>&nbsp;
                    <span>Αλφαβητικά</span>
                </button>
        </div>
        <div class="col-12 col-xl-6" >
            <hr data-v-4dbfbbaa="">
        </div>
        
        <div class="col-12 mob-column col-xl-3 d-flex justify-content-end">
            
            <div>
                <h3 class="color-700 fs-3 mb-0" style="letter-spacing:2px;">{{ categoryTitle }}</h3>
            </div>
        </div>
    </div>
    
    

    <!-- Business Cards Grid -->
    <div class="row row-cols-1 row-cols-md-3 g-4">
      <div class="col" v-for="business in businesses" :key="business.id">
        <router-link :to="`/profile/${categoryId}/${business.id}`" class="card-link">
          <div class="card h-100">
            <div class="image-container">
              <img
                :src="business.top_banner_img ? `/storage/${business.top_banner_img}` : require('@/assets/images/marketplace-logo.jpg')"
                class="card-img-top" :alt="business.brandname" /> <!-- Rounded Badge in the bottom-right corner -->
              <div class="rounded-badge">
                <span>{{ business.brandname }}</span>
              </div>
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ business.brandname }}</h5>
              <div
                class="d-flex flex-column-mobile align-items-baseline-mobile justify-content-between align-items-center">
                <div>
                  <div class="card-text mb-3 mb-xl-0">
                    <div class="d-flex align-items-center">
                      <div>
                        <img src="../assets/images/map.png" alt="" width="30">
                      </div>
                      <div>
                        {{ business.Address }}
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div>
                        <img src="../assets/images/Phone.png" alt="" width="30">
                      </div>
                      <div>
                        {{ business.Phone }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="logo-container" v-if="business.Logo">
                  <img
                    :src="business.Logo ? `/storage/${business.Logo}` : require('@/assets/images/marketplace-logo.jpg')"
                    alt="Logo" class="logo" />
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="modal fade" id="mapModal" tabindex="-1" aria-labelledby="mapModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="mapModalLabel">Χάρτης</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <LeafletMap v-if="isMapVisible" ref="leafletMap" :locations="locations" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Κλείσιμο
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBusinessPartnersPerCategoryId } from '@/services/business-partners';
import { useHead } from '@vueuse/head';
import { getBusinessCategory } from '@/services/category';
import LeafletMap from '@/components/LeaftletMap.vue';

export default {
  components: { LeafletMap },
  name: 'CategoryPage',
  data() {
    return {
      isMapVisible: false,
      locations: [
        // { title: "Nikos", address: "Μιλτιάδου 43, Γλυφάδα, 16675" },
        // { title: "Citrine", address: "Αετιδέων 43, Χολαργός, 15561" }, 
        // ... more markers
      ],
      categoryTitle: '', // Store the category title
      businesses: [], // Store the business data (offers)
      activeSort: 'random',
    };
  },
  computed: {
    categoryId() {
      return this.$route.params.id; // Get the category ID from the URL
    }
  },
  methods: {
    initializeMapOnModalShow() {
        const modal = document.getElementById('mapModal');
        modal.addEventListener('shown.bs.modal', () => {
            this.isMapVisible = true; // Render the LeafletMap component
            this.$nextTick(() => {
                if (this.$refs.leafletMap) {
                this.$refs.leafletMap.invalidateSize();
                }
            });
        });

        modal.addEventListener('hidden.bs.modal', () => {
            this.isMapVisible = false; // Unmount the LeafletMap component
        });
    },
    randomizeData() {
          if (Array.isArray(this.businesses)) {
              this.businesses = this.businesses.sort(() => Math.random() - 0.5);
              this.activeSort = 'random';
          } else {
              console.warn('Categories data is not an array.');
          }
      },
      sortAlphabetically() {
          if (Array.isArray(this.businesses)) {
              this.businesses = this.businesses.sort((a, b) =>
                  a.brandname.localeCompare(b.brandname)
              );
              this.activeSort = 'alphabetical';
          } else {
              console.warn('Categories data is not an array.');
          }
    },
    // Fetch businesses per category
    async fetchCategoryBusinesses() {
      try {
        const response = await getBusinessPartnersPerCategoryId({ business_category_id: this.categoryId });
        this.businesses = response.data.success ? response.data.data : [];
        let locations = [];
        if (this.businesses.length) {
          for (let i = 0; i < this.businesses.length; i++) {
            if (this.businesses[i].Lat && this.businesses[i].Lon) {
              locations.push({ "title": this.businesses[i].brandname, "lat": this.businesses[i].Lat, "lon": this.businesses[i].Lon, });
            }
          }
        }
        this.locations = locations;
      } catch (error) {
        console.error('Error fetching offers data', error);
        this.$toast.error('Error fetching offers data');
      }
    }
  },
  async mounted() {
    // Set the category title based on the category ID from the URL
    if (this.categoryId) {
      const c = await getBusinessCategory(this.categoryId);
      if (c.data.success) {
        this.categoryTitle = c.data.data.title;
      }
    }
    // Fetch the business data (offers) from the API
    this.fetchCategoryBusinesses();
    this.initializeMapOnModalShow();

    useHead({
      title: `Κατηγορίες - ${this.categoryTitle}`,
      meta: [
        { name: 'description', content: `Κατηγορίες - ${this.categoryTitle}` },
        { property: 'og:title', content: `Κατηγορίες - ${this.categoryTitle}` },
        { property: 'og:description', content: `Κατηγορίες - ${this.categoryTitle}` }
      ]
    });
  }
};
</script>

<style scoped>
.btn-sorting.active{
    background: #175576;
    color: #ffffff !important;
}
.btn-sorting{
    background: #ffffff;
    border: 1px solid #175576 !important;
    color: #175576 !important;
    padding: 2px 10px;
    border-radius: 99px;
}
.btn-sorting:hover {
    background: #8FBDE9;
    color: #ffffff !important;
    transition: .5s;
    border: 1px solid #8FBDE9 !important;
}
.fixed-button {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: #175576;
  color: #fff;
  border: none;
  border-radius: 10px 0px 0px 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1000; /* Ensures the button appears above other elements */
}

.fixed-button:hover {
  background-color: #8FBDE9;
}

.icofont-eye {
  font-size: 1.5rem;
  margin-bottom: 5px;
  writing-mode: vertical-rl; /* Makes text vertical */
  transform: rotate(180deg); /* Flips text to read correctly */}

.fixed-button .button-text {
  writing-mode: vertical-rl; /* Makes text vertical */
  transform: rotate(180deg); /* Flips text to read correctly */
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: center; /* Ensures text aligns properly */
}
.fixed-button .icofont-google-map{
    font-size: 24px;
    margin-bottom: 10px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

/* Modal Adjustments */


.modal-content {
  border-radius: 10px;
  margin-bottom:10px;
}
a:hover {
  transition: 1s;
  color: #A27B52;
}

.breadcrumb {
  background-color: transparent;
  padding-top: 20px;
}

.title-with-line {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.category-title {
  flex-shrink: 0;
  padding-left: 20px;
  /* Space between the line and the title */
  font-size: 2rem;
}

.title-with-line::before {
  content: '';
  flex-grow: 1;
  height: 2px;
  background-color: #000;
  /* Line color */
}

.image-container {
  position: relative;
  height: 250px;
  /* Fixed height for consistency */
  overflow: hidden;
}

.card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
  border: 1px solid #eee;
}

/* Rounded Badge */
.rounded-badge {
  position: absolute;
  bottom: 1px;
  right: 40px;
  background-color: #A27B52;
  padding: 2px 8px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 1rem;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.card-body {
  text-align: left;
}

.card-title {
  font-size: 1.50rem;
  font-weight: bold;
  padding-left: 5px;
}

.card-text {
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.logo {
  max-height: 50px;
  height: 40px
}

.badge {
  font-size: 0.85rem;
  padding: 0.5em 1em;
  border-radius: 5px;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.card {
  cursor: pointer;
  box-shadow: 3px 3px 10px 5px #eee;
  padding: 15px;
  border-radius: 30px;
}

@media only screen and (max-width: 768px) {
  .card-title {
    font-size: 1.25rem;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .align-items-baseline-mobile {
    align-items: baseline !important;
  }

  .breadcrumb {
    justify-content: center;
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-top: unset;
  }

  .logo-container {
    margin: 0 auto;
  }
  .mob-column{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
  }
}
</style>